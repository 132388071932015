import React from 'react'
import styled from 'styled-components'

import getStoreFromPath from '../../utils/getStoreFromPath'
import categories from '../../utils/StoreHomeCategories'
import StoreLayout from '../../components/StoreLayout'
import HowToBuy from '../../components/HowToBuy'
import HomeCategoryGrid from '../../components/HomeCategoryGrid'

const Main = styled.main`
    margin: 1rem 0;
`

const Header = styled.div`
    margin: 0 var(--spacing-global);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h1 {
        margin-bottom: 0;
    }
`

const EmersonPage = ({ location }) => {
    const store = getStoreFromPath(location.pathname)

    return (
        <StoreLayout
            ContentComponent={Main}
            location={location}
            seotitle="Smile"
            seourl={location.pathname}
        >
            <Header>
                <h1>Smile</h1>
                <HowToBuy />
            </Header>

            <HomeCategoryGrid categories={categories} store={store} />
        </StoreLayout>
    )
}

export default EmersonPage
